.governmentLevel {
  @media (max-width: 767px) {
      display: block;
  }
  .govt-level-title {
    font-size: 32px;
    color: #003459;
    border-bottom: 2px solid #003459;
    display: inline-block !important;
  }
}
